import { makeVar } from "@apollo/client"
import {
  getPersistedCityName,
  getQueryCityName,
  getSelectedCity,
  ICity,
  persistCityName,
} from "./city-manager"

// status of the contact widget
export const showWidgetVar = makeVar(false)

export interface ISelectedCity {
  input: string
  city: ICity | null
}
const initalCityInput = getQueryCityName() || getPersistedCityName()

// selected city for contact information
export const selectedCityVar = makeVar<ISelectedCity>({
  input: initalCityInput,
  city: getSelectedCity(initalCityInput),
})

/**
 * Sets the selectedCityVar and looks for the entered city.
 * @param cityName entered city name
 */
export const setCityInput = (cityName: string) => {
  const city = getSelectedCity(cityName)
  persistCityName(city ? city.name : "")
  selectedCityVar({ input: city ? city.name : cityName, city })
}
