import supportedCitiesApi from "../../static/api/cities.json"

const CITY_KEY = "selectedCity"
const CITY_QUERY_KEY = "city"

export interface ICityContact {
  iconType: string
  typeName: string
  name: string
  url: string
}

export interface ICity {
  name: string
  position: { top: string; left: string }
  contact: ICityContact[]
}

/**
 * all cites with their contact details that use the BürgerBot already.
 */
export const supportedCities = supportedCitiesApi

/**
 * Finds the city with the entered name.
 * @param cityName city name
 */
export const getSelectedCity = (cityName: string) => {
  const filteredCities = supportedCities.filter(
    (city) => city.name.toLowerCase() === cityName.trim().toLowerCase()
  )
  return filteredCities.length > 0 ? filteredCities[0] : null
}

/**
 * Saves the selected city.
 * @param cityName selected city name
 */
export const persistCityName = (cityName: string) => {
  if (document.cookie.includes("CookieConsent=true")) {
    window.localStorage.setItem(CITY_KEY, cityName)
  }
}

/**
 * Gets the last saved city.
 */
export const getPersistedCityName = (): string => {
  return typeof window !== "undefined"
    ? window.localStorage.getItem(CITY_KEY) || ""
    : ""
}

/**
 * Gets the city name from the query param.
 */
export const getQueryCityName = (): string => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has(CITY_QUERY_KEY)) {
      return urlParams.get(CITY_QUERY_KEY)
    }
  }
  return ""
}
