import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useReactiveVar } from "@apollo/client"

import "./contact-bot-widget.css"
import CityInput from "./city-input"
import { selectedCityVar, showWidgetVar } from "../utils/reactive-vars"

const CITY_INPUT_ID = "city-input-widget"

/**
 * Widget for displaying the contact information for cities that already use the bot.
 */
const ContactBotWidget = () => {
  const show = useReactiveVar(showWidgetVar)
  const selectedCity = useReactiveVar(selectedCityVar)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-circle.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoClose: file(relativePath: { eq: "logo-close.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (show && !(selectedCity && selectedCity.city)) {
      const cityInput = document.getElementById(CITY_INPUT_ID)
      cityInput && cityInput.focus()
    }
  }, [show])

  const buttonImage = show ? data.logoClose : data.logo

  return (
    <div className="bot-widget">
      {show && (
        <div
          className="bot-widget-content"
          data-aos="fade-up"
          data-aos-duration="200"
        >
          <label htmlFor={CITY_INPUT_ID}>Stadt auswählen:</label>
          <CityInput id={CITY_INPUT_ID} />
          {selectedCity.city &&
            selectedCity.city.contact.map((contact, i) => (
              <a
                key={i}
                href={contact.url}
                target="_blank"
                className="custom-btn btn mt-3"
              >
                <i className={`fa fa-${contact.iconType}`}></i>{" "}
                {contact.typeName}
              </a>
            ))}
        </div>
      )}

      <button
        className="bot-widget-toggle"
        onClick={() => {
          showWidgetVar(!show)
        }}
      >
        <Img fixed={buttonImage.childImageSharp.fixed} />
      </button>
    </div>
  )
}

export default ContactBotWidget
