import React, { MouseEvent } from "react"

export const escapeEmailAddress = (email: string[]) =>
  email.join("<!---->@<!---->")

/**
 * Component to prevent visible mailto links.
 */
const MailToLink = ({ email, ...props }) => {
  const onClick = (e: MouseEvent) => {
    e.preventDefault()
    window.location.href = `mailto:${email.join("@")}`
  }

  return <a {...props} onClick={onClick} href="#" />
}

export default MailToLink
