/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from "aos"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"
import ContactBotWidget from "./contact-bot-widget"
import JsStyleLoader from "./js-style-loader"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          contact {
            email
            phone
            facebook
          }
        }
      }
    }
  `)

  /**
   * Called if cookies are accepted.
   */
  const onAcceptCookies = () => {
    //@ts-ignore
    if (window._paq) {
      //@ts-ignore
      window._paq.push(["setConsentGiven"])
    }
  }

  useEffect(() => {
    AOS.init({
      duration: 800,
      anchorPlacement: "center-bottom",
    })
  }, [])

  return (
    <>
      <JsStyleLoader styleUrl="/aos.css" />
      <Header
        siteTitle={data.site.siteMetadata.title}
        email={data.site.siteMetadata.contact.email}
      />
      {children}
      <ContactBotWidget />
      <CookieConsent
        location="bottom"
        buttonText="Fortfahren"
        style={{
          alignItems: "center",
          background: "rgba(53, 53, 53, 0.9)",
          padding: "1em",
        }}
        buttonClasses="custom-btn btn-bg btn m-2"
        contentStyle={{}}
        disableButtonStyles={true}
        onAccept={onAcceptCookies}
      >
        Diese Webseite nutzt Cookies und andere Techniken, um zu verstehen, wie
        die Webseite genutzt wird und Ihnen die Nutzung zu erleichtern.
      </CookieConsent>
      <Footer contact={data.site.siteMetadata.contact} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
