import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"

import "./footer.css"
import MailToLink, { escapeEmailAddress } from "./mailto-link"

interface IProps {
  contact: {
    email: string
    phone: string
    facebook: string
  }
}

const Footer = ({ contact }: IProps) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allSupportedCity {
        nodes {
          id
          name
          sitePath: gatsbyPath(filePath: "/staedte/{SupportedCity.name}")
        }
      }
    }
  `)
  const supportedCities = data.allSupportedCity.nodes || []

  return (
    <footer className="site-footer">
      <Container>
        <Row>
          <Col className="col-lg-5 mx-lg-auto col-md-8 col-10">
            <h1 className="text-white">
              Holen Sie den <strong>BürgerBot</strong> in Ihre Stadt
            </h1>
          </Col>

          <Col className="col-lg-3 col-md-6 col-12">
            <h4 className="my-4">Kontakt</h4>

            <p className="mb-1">
              <a href={`tel:${contact.phone}`}>
                <i className="fa fa-phone mr-2 footer-icon"></i>
                {contact.phone}
              </a>
            </p>

            <p>
              <MailToLink email={contact.email}>
                <i className="fa fa-envelope mr-2 footer-icon"></i>
                <span
                  dangerouslySetInnerHTML={{
                    __html: escapeEmailAddress(contact.email),
                  }}
                />
              </MailToLink>
            </p>
          </Col>

          <Col className="col-lg-3 col-md-6 col-12">
            <h4 className="my-4">Städte</h4>

            {supportedCities.map((city) => (
              <p className="mb-1">
                <Link to={city.sitePath}>BürgerBot {city.name}</Link>
              </p>
            ))}
          </Col>

          <Col className="col-lg-4 mx-lg-auto text-center col-md-8 col-12">
            <p className="copyright-text">
              Copyright &copy; {new Date().getFullYear()}{" "}
              <a href="https://www.viind.com" target="_blank">
                viind GmbH
              </a>
            </p>
          </Col>

          <Col className="col-lg-4 mx-lg-auto col-md-6 col-12">
            <ul className="footer-link">
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutzerklärung</Link>
              </li>
            </ul>
          </Col>

          <Col className="col-lg-3 mx-lg-auto col-md-6 col-12">
            <ul className="social-icon">
              <li>
                <a
                  href={contact.facebook}
                  target="_blank"
                  className="fa fa-facebook"
                ></a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  contact: PropTypes.object,
}

Footer.defaultProps = {
  siteTitle: ``,
  contact: {
    email: ``,
    phone: ``,
    facebook: ``,
  },
}

export default Footer
