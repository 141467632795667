import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

/**
 * Loads a css stylesheet only if Javascript is active.
 */
const JsStyleLoader = ({ styleUrl }) => {
  const [load, setLoad] = useState(false)

  useEffect(() => {
    setLoad(true)
  }, [])

  return load ? (
    <Helmet>
      <link type="text/css" rel="stylesheet" href={styleUrl} />
    </Helmet>
  ) : null
}

export default JsStyleLoader
