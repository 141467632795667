import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar, Container, Nav } from "react-bootstrap"
import { showWidgetVar } from "../utils/reactive-vars"

import "./header.css"
import MailToLink from "./mailto-link"

const Header = ({ siteTitle, email }) => {
  const [navExpanded, setNavExpanded] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-circle.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const onNavToggleClick = () => {
    setNavExpanded(!navExpanded)
    showWidgetVar(false)
  }

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Link className="navbar-brand" to="/">
            <Img
              fixed={data.file.childImageSharp.fixed}
              style={{ width: 50, float: "left", marginRight: 10 }}
            />
            {siteTitle}
          </Link>
          <Navbar.Toggle
            aria-controls="navbarNav"
            aria-expanded={navExpanded}
            onClick={onNavToggleClick}
            label="Navigation umschalten"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="navbarNav">
            <Nav className="ml-auto">
              <Nav.Link to="/buerger" as={Link}>
                Für Bürger
              </Nav.Link>
              <Nav.Link to="/staedte" as={Link}>
                Für Städte
              </Nav.Link>
              <Nav.Link to="/faq" as={Link}>
                FAQ
              </Nav.Link>
              <Nav.Item>
                <MailToLink className="nav-link contact" email={email}>
                  Team kontaktieren
                </MailToLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="navbar-spacer" />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
